.single {
	border-bottom-width: 1px;
	border-bottom-style: dashed;
	border-bottom-color: var(--bs-border-color);
	padding: 1.5rem 0;

	&:last-child {
		border-bottom: none;
	}

}