body .react-select__control {
	background: transparent;
	border: 1px solid var(--bs-gray-300);
	transition: color 0.2s ease;
	width: 100%;
	padding: 0.475rem 0 0.475rem 0;
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 1.5;
	box-shadow: none;
	cursor: pointer;


	&:hover {
		border-color: var(--bs-gray-300);
	}

	&.react-select__control--is-focused {
		border-color: var(--bs-gray-400);
	}

	.react-select__input-container {
		padding: 0;
		margin: 0;
		color: var(--bs-gray-700);

	}

	.react-select__value-container--has-value .react-select__single-value {
		color: var(--bs-dark) !important;
	}

	.react-select__value-container--is-multi.react-select__value-container--has-value {
		padding: 0 0.475rem 0 0.475rem;
	}

	//.react-select__value-container, .react-select__indicator {
	//    padding: 0;
	//}

	.react-select__indicator {
		padding: 0 8px;

		&.react-select__clear-indicator {
			padding: 0;
		}
	}

	.react-select__value-container {
		padding: 0.3rem 0.775rem 0.3rem 1rem;
	}

	.react-select__single-value {
		color: var(--bs-gray-700);
		margin: 0;
	}

	.react-select__indicator-separator {
		display: none;
	}

	.react-select__multi-value {
		background-color: var(--bs-gray-200);

		& + .react-select__input-container {
			margin-left: 0.5rem;
		}

		.react-select__multi-value__label {
			color: var(--bs-gray-700);
		}

		.react-select__multi-value__remove {
			transition: 0.2s ease all;

			svg {
				fill: var(--bs-gray-700);
				transition: 0.2s ease scale;
			}

			&:hover {
				background-color: var(--bs-gray-200);
				transition: 0.2s ease all;

				svg {
					fill: var(--bs-gray-700);
					scale: 1.2;
					transition: 0.2s ease scale;
				}
			}
		}

	}

}

body .form-control-solid .react-select__control {
	background-color: var(--bs-gray-100);
	border-color: var(--bs-gray-100);
	color: var(--bs-gray-700);
	transition: color 0.2s ease;

	&.react-select__control--is-focused {
		background-color: var(--bs-gray-200);
		border-color: var(--bs-gray-200);
		color: var(--bs-gray-700);
		transition: color 0.2s ease;
	}
}


body .react-select__menu {
	border: 0;
	box-shadow: var(--bs-dropdown-box-shadow);
	border-radius: 0.475rem;
	padding: 1rem 0;
	background-color: var(--bs-dropdown-bg);


	.react-select__menu-list {
		padding: 0;
	}

	.react-select__option {
		cursor: pointer;
		transition: color .2s ease;
		color: var(--bs-body-color);
	}


	.react-select__option--is-focused {
		background-color: var(--bs-component-hover-bg);
		color: var(--bs-component-hover-color);
		transition: color .2s ease;
	}

	.react-select__option--is-selected {
		background-color: var(--bs-component-hover-bg);
		color: var(--bs-component-hover-color);
		position: relative;

		&:after {
			top: 50%;
			display: block;
			position: absolute;
			transform: translateY(-50%);
			height: 0.75rem;
			width: 0.75rem;
			content: "";
			mask-repeat: no-repeat;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: center;
			background-color: var(--bs-component-hover-color);
			-webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='var%28--bs-component-hover-color%29' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
			mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='var%28--bs-component-hover-color%29' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
			mask-position: center;
			-webkit-mask-position: center;
			right: 1.25rem;
		}
	}


}
