@import "wallet";
@import "apiKeys";
@import "mobileTable";
@import "merchant";
@import "reactSelect";
@import "basket";
@import "loading";
@import 'react-tooltip/dist/react-tooltip.css';
@import "balanceCard";


.menu-sub-dropdown {

	.menu-item {
		.menu-link {
			i {
				font-size: 1.4rem;
				margin-right: 0.5rem;
			}
		}
	}
}


.coinName {
	display: flex;
	align-items: center;
	gap: 1rem;

	&.lg {
		.coinNameText {
			font-size: 1.5rem !important;
			font-weight: 100 !important;
			line-height: 0.8;
		}

		.coinCode {
			font-size: 1.2rem !important;
			font-weight: 700 !important;
		}


		.coinLogo {
			width: 150px;
			height: 150px;
			padding: 1rem;
		}
	}

	.coinLogo {
		padding: 10px;
		width: 50px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.475rem;

		img {
			max-height: 100%;
			max-width: 100%;
			//filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.7));

		}
	}
}

.balance {
	display: flex;
	align-items: flex-start;
	gap: 0.25rem;
	justify-content: flex-end;

	&.lg {
		.amount {
			font-size: 2rem !important;
			font-weight: 100 !important;
		}

		.coinUnitCode {
			font-weight: 700 !important;
			font-size: 1rem !important
		}
	}

	.coinUnitCode {
		min-width: 27px;
		text-align: left;
	}
}

.mobileTable {
	display: flex;
	flex-direction: column;

	.single {


		&.history {
			.info {
				display: grid;
				//grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
				grid-template-columns: 1fr;

				.item {
					display: grid;
					grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
					text-align: center;
					padding: 0.5rem;

					div, span {
						justify-content: flex-start !important;
						text-align: left;
					}
				}

				span.label {
					text-align: left;
					display: block;
					margin-bottom: 0.5rem;
				}
			}
		}

		.actions {
			display: flex;
			margin-top: 1rem;
			flex-direction: column;
			gap: 1rem;

			.btn {
				display: block;
				width: 100%;
			}
		}

		.info {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.last {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}

			.coinUnitCode {
				min-width: auto;
			}
		}
	}

}

.menu-item button.menu-link {
	background: transparent;
}

.text-right {
	text-align: right !important;
}

.btn-block {
	display: block;
	width: 100%;
}

.actionButtons {
	display: flex;
	justify-content: flex-end;
	gap: 0.5rem
}

.tableLoading {
	position: absolute;
	left: 0;
	top: 0;
	background: rgba(255, 255, 255, 0.5);
	width: 100%;
	height: 100%;
	z-index: 2;
	display: flex;
	padding-top: 3rem;
	justify-content: center;
}

html[data-bs-theme=dark] .tableLoading {
	background: rgba(0, 0, 0, 0.5);
}

.dataTable.table:not(.table-bordered) tr:first-child, .dataTable.table:not(.table-bordered) th:first-child, .dataTable.table:not(.table-bordered) td:first-child {
	padding-left: 0.75rem;
}

.dataTable.table:not(.table-bordered) tr:last-child, .dataTable.table:not(.table-bordered) th:last-child, .dataTable.table:not(.table-bordered) td:last-child {
	padding-right: 0.75rem;
}

.is-invalid .react-select__control, .is-invalid .react-select__control:hover {
	border-color: var(--bs-form-invalid-border-color);
	padding-right: calc(1.5em + 1.55rem);
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23f1416c%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23f1416c%27 stroke=%27none%27/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: right calc(0.375em + 0.3875rem) center;
	background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}

.is-invalid .react-select__control--is-focused {
	border-color: var(--bs-form-invalid-border-color) !important;
	box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

@media (max-width: 768px) {

	.actionButtons {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.card .card-header {
		flex-direction: column;

		h3.card-title {
			align-items: center !important;

			span {
				text-align: center;
			}
		}
	}

	.table-responsive .dataTable {
		min-width: 1000px;
	}
}

html {
	--react-international-phone-height: 44px;
	--react-international-phone-background-color: var(--bs-body-bg);
	--react-international-phone-text-color: var(--bs-gray-700);
	--react-international-phone-border-color: var(--bs-gray-300);
	--react-international-phone-font-size: 1.1rem;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.react-international-phone-input-container .react-international-phone-input.is-invalid {
	border-color: var(--bs-form-invalid-border-color) !important;
}

.react-international-phone-input-container .react-international-phone-input.is-valid {
	border-color: var(--bs-form-valid-border-color) !important;
}