.BalanceCard {
	&.BigIconVersion {

		.coinIcon {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				height: 200%;
				width: auto !important;
				opacity: 0.1;

			}
		}

		.textArea {
			position: relative;
			z-index: 1;
			align-items: center;
			width: 100%;
		}

		.card-body {
			overflow: hidden;
			position: relative;
		}

	}

	&.RightIconVersion {
		.card-body {
			flex-direction: row-reverse !important;
			align-items: center !important;
		}

		.coinIcon {
			height: 100px;
			width: 100px;
			text-align: center;

			img {
				height: 100%;
				width: auto !important;
				opacity: 0.8;
			}
		}
	}
}