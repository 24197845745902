.myWallet {

	.deposit {
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
		width: 100%;

		.label {
			font-size: 1.2rem;
			font-weight: 700;
			margin-bottom: 0.25rem;
		}

		.first, .last {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0 3rem;
			flex-grow: 1;
		}

		.first {
			padding-top: 2rem;
			padding-bottom: 2rem;
			border-right-width: 1px;
			border-right-style: dashed;
			border-right-color: var(--bs-border-color);
		}


		.coinName {
			margin-bottom: 1rem;
			flex-direction: column;

			.coinNameInfo {
				align-items: center;
			}
		}

		.balanceArea {
			margin-top: 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			.label {
				font-size: 1.2rem;
				font-weight: 700;
			}
		}


		img.qrImage {
			width: 250px;
			max-width: 100%;
		}

		.walletAddress {
			margin-top: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;


			.address {
				background: var(--bs-gray-100);
				padding: 0.5rem 1rem;
				border-radius: 0.475rem;
				font-size: 1.5rem;
				font-weight: 100;
				word-wrap: anywhere;
				text-align: center;
			}
		}

		.warningText {
			margin-top: 2rem;
			text-align: center;
			max-width: 500px;

			span {
				word-wrap: break-word;
			}
		}


	}


	.withdraw {
		margin: 0 auto;
		width: 700px;
		max-width: 100%;


		.col-form-label {
			text-align: left;
			font-weight: bold !important;
		}

		.prefix {
			position: absolute;
			left: 0.7rem;
			top: 0.7rem;

			img {
				width: 25px;
				height: 25px;
				opacity: 0.2;
			}
		}

		.suffix {
			position: absolute;
			right: 0.7rem;
			top: 0.7rem;
			text-align: left;

			img {
				width: 25px;
				height: 25px;
				opacity: 0.2;
			}
		}

		.fakeInput {
			width: 100%;
			padding: 0.775rem 2.3rem 0.775rem 3rem;
			font-size: 1.1rem;
			font-weight: 500;
			line-height: 1.5;
			text-align: right;
			background-clip: padding-box;
			border: 1px solid var(--bs-gray-200);
			appearance: none;
			border-radius: 0.475rem;
			position: relative;
			//background-color: var(--bs-gray-100);
			border-color: transparent;
			color: var(--bs-gray-600);

			&.code4 {
				padding-right: 3rem;
			}

			&.testNetworkCode {
				padding-right: 3.5rem;
			}

			&.hoverable {
				color: var(--bs-primary);
				cursor: pointer;
			}
		}

		.customInput {
			position: relative;


			&.code4 {
				padding-right: 3rem;
			}

			&.coinInput {
				input {
					padding: 0.775rem 2.3rem 0.775rem 3rem;
				}

				&.testNetworkCode input {
					padding-right: 3.5rem;
				}

				&.is-valid, &.is-invalid {
					padding-right: 2.3rem;

					&.code4 {
						padding-right: 3rem;
					}

					&.testNetworkCode input {
						padding-right: 3.5rem;
					}
				}
			}


			.prefix img {
				opacity: 1;
			}

			input {
				color: var(--bs-gray-800);
			}

			&.is-valid, &.is-invalid {
				background-position: left 4rem center;
				padding-right: 1rem;
			}
		}

	}


}

@media (max-width: 768px) {

	.toolsButtons {
		flex-direction: column;
		width: 100%;
		margin-bottom: 1rem;

		& > a {
			display: block;
			width: 100%;
		}
	}
	.myWallet {


		.card-header, .card-body {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		.deposit {
			flex-direction: column;

			.first, .last {
				padding-left: 0;
				padding-right: 0;
			}

			.first {
				border-right: none;
				order: 2;
			}

			.last {
				order: 1;
				flex-direction: row;
				justify-content: space-evenly;
				width: 100%;
			}

			.balanceArea {
				margin-top: 0;
			}

			.walletAddress .address {
				font-size: calc(1rem + 0.8vw);
			}

			.coinName {
				flex-direction: row;
				margin-bottom: 0;

				.coinNameInfo {
					align-items: flex-start;
				}
			}
		}

	}
}

@media (max-width: 400px) {
	.myWallet .deposit .last {
		justify-content: space-between;
	}
}

@media (max-width: 991px) {
	.myWallet .withdraw .col-form-label {
		text-align: left !important;
	}
}