
#Payment {
	width: 1000px;
	max-width: 100%;
	margin: auto;

	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;

		.logo {
			img {
				display: block;
				height: 50px;
			}
		}

		.tools {
			display: flex;

		}

		.countdown {
			display: flex;
			align-items: center;
			font-size: 1.5rem;
			gap: 0.5rem;
			font-weight: 700;

			img {
				height: 30px;
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;

		button {
			padding-left: 8rem;
			padding-right: 8rem;
			position: relative;
			overflow: hidden;

			i {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				font-size: 3rem;
				opacity: 0.5;
			}

			span.loading {
				position: absolute;
				right: 1rem;
				top: 50%;
				transform: translateY(-50%);

				span {
					width: 1.8rem;
					height: 1.8rem;
				}
			}
		}
	}

	.basketCards {
		display: flex;
		gap: 2rem;
		align-items: stretch;
		width: 100%;

		.first, .last {
			display: flex;
			flex-direction: column;
			gap: 2rem;
		}

		.first {
			min-width: 400px;
		}

		.last {
			flex-grow: 1;
		}

		&.payStep {
			.first {
				min-width: 500px;
				max-width: 500px;
			}
		}

		.basketCard {
			background: var(--bs-body-bg);
			border-radius: 1rem;
			padding: 2rem;
			border: 1px solid var(--bs-payment-border-color);
			position: relative;
			//overflow: hidden;
			height: 100%;
			display: flex;
			flex-direction: column;

			&.passive {
				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(255, 255, 255, 0.5);
					z-index: 1;
				}
			}

			&.hiding {
				animation: hide 0.5s ease-in-out forwards;
			}

			.title {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 0.5rem;
				margin-bottom: 1rem;

				img {
					height: 30px;
					opacity: 0.3;
				}

				h2 {
					margin-bottom: 0;
				}
			}

			section.success, section.timeout {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;

				img {
					margin-top: 2rem;
					max-height: 150px;
					margin-bottom: 2rem;
				}
			}

			section.paySection {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				height: 100%;

				.qrCode {
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						max-width: 100%;
						height: 100%;
						width: 300px;
						object-fit: cover;
					}
				}

				.label {
					font-size: 1.2rem;
					font-weight: 700;
					margin-bottom: 0.25rem;
				}

				.timeLeft {
					margin-top: 1rem;
					display: flex;
					flex-direction: column;
					align-items: center;

					.time {
						font-size: 1.5rem;
						//background: var(--bs-gray-200);
						//padding: 0.5rem 1rem;
						border-radius: 0.475rem;
					}
				}

				.amount {
					margin-bottom: 1rem;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.amountWithCode {
						display: flex;
						align-items: flex-start;
						gap: 0.2rem;

						small {
							font-weight: 700;
							font-size: 0.8rem;
						}
					}

					.copyArea {
						display: flex;
						align-items: center;
						gap: 0.5rem;
						background: transparent;
						//color: var(--bs-light);
						padding: 0.5rem 3rem;

						.copy {
							background: var(--bs-dark);
							color: var(--bs-light);
						}
					}

				}

				.copyArea {
					background: var(--bs-gray-100);
					padding: 0.5rem 1rem;
					border-radius: 0.475rem;
					font-size: 1.5rem;
					font-weight: 100;
					word-wrap: anywhere;
					text-align: center;
					position: relative;
					cursor: pointer;
					overflow: hidden;

					.copy {
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						z-index: 1;
						opacity: 0;
						background: var(--bs-gray-200);
						transition: all 0.5s ease-in-out;

						&.show {
							opacity: 1;
							transition: all 0.5s ease-in-out;
						}
					}
				}

				.walletAddress {
					margin-top: 1rem;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;


				}

				.addressWarning .warningText {
					font-weight: 500;
					color: var(--bs-gray-900);
				}
			}

			table {
				tbody {

					tr td {
						&.key {
							color: var(--bs-gray-700);
							display: flex;
							align-items: center;
							padding-right: 5rem;
						}

						&.value {
							font-weight: 500;
							color: var(--bs-gray-900);
							text-align: right;

							a {
								color: var(--bs-gray-900);

								&:hover {
									color: var(--bs-primary);
								}
							}
						}
					}
				}
			}

			.currencyList {
				height: 100%;
				flex-grow: 1;
				display: flex;
				align-items: center;

				.currencies {
					flex-direction: column;
					gap: 1rem;
					width: 100%;
					display: grid;
					grid-template-columns: 1fr 1fr;
					overflow: auto;

					&.onlyOne {
						grid-template-columns: 1fr;
					}

					&.leastOneSelected {
						.currency:not(.active) {
							opacity: 0.5;
						}
					}

					.currency {
						border: 1px solid var(--bs-payment-border-color);
						cursor: pointer;
						border-radius: 0.475rem;
						width: 100%;
						display: flex;
						justify-content: space-between;
						align-items: center;
						overflow: hidden;
						position: relative;
						transition: all 0.2s ease-in-out;

						&.active {
							transition: all 0.2s ease-in-out;


							.coinInfo {
								color: #FFF;
							}

							&.btc, &.btc-test {
								background: #f7931a;
							}

							&.ltc {
								background: #345d9d;
							}

							&.eth {
								background: #071a38;
							}

							&.xrp {
								background: #1a2c3f;
							}

							&.doge {
								background: #ba9f33;
							}

							&.dash {
								background: #008ce7;
							}

							&.trx {
								background: #500e0e;
							}

							&.usdt {
								background: #50af95;
							}


							&.bnb {
								background: #f0b90b;
							}

							&.busd {
								background: #695107;
							}
						}


						.coinLogo {
							height: 45px;
							width: 45px;
							display: flex;
							justify-content: center;
							padding: 0.2rem;
							margin-left: 0.5rem;

						}

						.coinInfo {
							padding: 1rem;
							display: flex;
							flex-direction: column;
							align-items: flex-end;
							position: relative;
							z-index: 1;
							color: var(--bs-body-color);

							.amount {
								font-weight: 500;
								font-size: 1.075rem;
								display: flex;
								align-items: flex-start;
								gap: 0.25rem;
								//text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);

								span {
									font-size: 0.75rem;
									font-weight: 700;
								}
							}

							.coinName {
								font-weight: 500;
								font-size: 1.2rem;
							}
						}
					}
				}
			}


		}

	}

}

html[data-bs-theme=dark] {
	--bs-payment-border-color: #1E2027;
}

html[data-bs-theme=light] {
	--bs-payment-border-color: #F1F1F4;
}

@media (max-width: 999px) {
	html[data-bs-theme=light] #Payment .top .countdown {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}
	html[data-bs-theme=dark] #Payment .top .countdown {
		box-shadow: 0 0 10px rgba(0, 0, 0, 1);
	}
	#Payment {
		padding-bottom: 8rem;

		.top {
			padding: 1rem 1rem 0;

			.countdown {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				background: var(--bs-body-bg);
				padding: 2rem 1rem;
				z-index: 10;
				justify-content: center;
			}
		}

		.basketCards {
			flex-direction: column;

			.first, .last {
				min-width: 0 !important;
				padding-left: 1rem;
				padding-right: 1rem;
			}

			.basketCard {
				table tbody tr td.key {
					padding-right: 1rem;
				}

			}

			&.payStep .first {
				max-width: 100%;
			}
		}

		.bottom {
			padding: 0 1rem 5rem;

			button {
				width: 100%;
			}
		}
	}
}

@media (max-width: 500px) {
	#Payment .basketCards .basketCard .currencyList .currencies {
		grid-template-columns: 1fr;
	}
}

@keyframes hide {
	0% {
		opacity: 1;
		scale: 1;
	}

	100% {
		opacity: 0;
		scale: 0;
	}
}