.apiKeys {
	.longKey {
		word-wrap: anywhere;
	}

	&.viewLog {
		.single .info {
			display: flex;
			flex-direction: column;

			.topInfo {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}

	.single {
		.info {
			display: grid;
			grid-template-areas:
    "item1 item2 item3"
    "item4 item4 item4"
    "item5 item5 item5";
			grid-template-columns: 1fr 1fr 1fr;
			gap: 1rem;

			.item {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				span {
					text-align: center;
				}
			}

			.item1 {
				grid-area: item1;
			}

			.item2 {
				grid-area: item2;
			}

			.item3 {
				grid-area: item3;
			}

			.item4 {
				grid-area: item4;
			}

			.item5 {
				grid-area: item5;
			}
		}
	}
}