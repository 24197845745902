.coinAcceptance {
	.coinName {
		flex-shrink: 0;
		padding-right: 1rem;
		cursor: pointer;
	}

	.seperator {
		width: 100%;
		border-top: 1px solid var(--bs-border-color);
	}

	.discountRateInput {
		position: relative;
		display: inline-flex;
		align-items: center;


		input {
			padding-right: 1.5rem;
			//max-width: 4rem;
			text-align: right;
			font-weight: bolder;
		}

		span.suffix {
			position: absolute;
			right: 0.5rem;

			i {
				font-size: 1rem;
			}
		}
	}
}

.ipnHistory {
	.split50 {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.single {
		padding: 1rem 0;
	}

	.item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
		text-align: center;
		padding: 0.5rem;
		.label {
			text-align: center;
		}
	}
}

//@media(max-width: 400px) {
//	.coinAcceptance {
//		.coinName {
//			flex-shrink: 1;
//		}
//	}
//}